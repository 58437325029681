.tab{
    color: cadetblue;
    font-size: large;
    font-family: fantasy;
    font-weight: bolder;
    text-decoration: none;
}

.tabHolder{
    margin-top: 20px;
    margin-bottom: 20px;
}

.tab:hover{
    color:darkgray;
}

.tab:after {
    color:darkgray;
	content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(0,1);
	transform: scale(0,1);
}

.tab:hover:after {
    color:darkgray;
  opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.logoColor{
    color: thistle;
}


.iB{
    display: inline-block;
}

.mTMenue{
    margin-top: 25%;
}

.mLMenue{
    margin-left: 30%;
}

.m10{
    margin: 10px;
    margin-left: 18px;
}

.logoBlock{
    padding: 5px;
    border-style: solid;
    border-color:rgb(77, 91, 92);
    font-size: 19px;
    font-family: monospace;
    border-radius: 50%;
}


.sidenav{
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 15%; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 10; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black */
  overflow-x: hidden;
  padding-top: 20px;
  overflow: auto;
}

.mainBody{
    height: 100%;
    color: white;
}

.homeMainBody{
    margin-left: 15%;
    height: 100%;
    color: white;
}

.aboutMeImg{
    height: 200px;
    border-radius: 100px;
    margin-top: 25px;
}

.aboutMeRoot{
    height: 100%;
    margin-left: 18%;
    position: relative;
    margin-bottom: 5%;
    overflow : auto;
}

.aboutMeText{
    margin-left: 23%;
    margin-right: 5%;
    font-family: fantasy;
    margin-bottom: 20%;
}

.aboutMeFirstPara{
    padding-top: 50px;
}

.aboutMeImageIntro{
    align-items: center;
    flex-direction: column;
    display: flex;
    position: absolute;
}

.listElementBody{
    display: flex;
    box-shadow: 0px 0px 32px 17px #5d5d63;
    margin : 70px;
    width: 56%;
    padding: 10px;
    border-radius: 25px;
}

.listElemetImage{
    height: 150px;
    border-radius: 41px;
}

.listElemetHeading{
    text-transform: capitalize;
    font-size: 30px;
    font-family: fantasy;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 95%;
    margin-bottom: 12px;
    color: aquamarine;
}

.listElemetDesc{
    font-size: 18px;
    font-family: monospace;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 480px;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.style_Ellipsis {
    /* font-size: 20px; */
  }

.sideNavButton{
    display: none;
    /* position: absolute; */
    background: cadetblue;
    padding: 2%;
    z-index: 400;
    border: 1px solid;
    padding: 10px;
    box-shadow: 5px 10px 8px #7c3838;
}

.display{
    display: block ;
}
.notDisplay{
    display: none;
}

.cHFlex{
    display: flex;
    justify-content: center;
}
.contentHoderHeading{
    position: absolute;
    color: #9bfff6;
    text-transform: capitalize;
    font-size: 30px;
    font-family: fantasy;
    z-index: 4;
}
.mL15{
    margin-left: 15%;
}


.contactInfoName{
    margin-top: 30px;
    font-size: 30px;
    font-family: fantasy;
    font-weight: bold;
}

.contactInfo2{
    margin-top: 5px;
    font-size: 18px;
}

.contentHoderHeadingBg{
    width: 100%;
    height: 115px;
    filter: blur(4px);
}

.contentHolder{
    margin-left: 3%;
}

.headinMeta{
    margin-top: 30px;
    margin-bottom: 30px;
}

.mainContent{
    width: 75%;
    margin: 10%;
}

.autorization{
    position: absolute;
    bottom: 18px;
    color: antiquewhite;
    z-index: 90;
    margin: 25px;
}

.twitter_ovverride{
    border-radius: 0px !important;
    margin-left: 30px;
}

.contactMeForm{
    width: 36%;
    box-shadow: 0px 0px 32px 17px #5d5d63;
    margin : 70px;
    padding: 10px;
    border-radius: 25px;
}

.formFrameWidth{
    width: 500px;
}

@media only screen and (max-width: 412px) {
    /* navigation bar */

    .sidenav {
        position: absolute;
        width: 51%;
        height: 100%;
        margin-top: 55px;
    }
    .display{
        display: block;
    }

    .notDisplay{
        display: none;
    }
    .homeMainBody{
        margin-left: 0px;
    }

    /*About Me Page */

    .aboutMeFirstPara{
        padding-top: 5px;
    }
    .aboutMeImageIntro{
        position: relative;
    }
    .aboutMeRoot{
        margin-left : 0%;
    }
    .aboutMeText{
        margin-left: 15%;
    }

    /*side nave button */
    .sideNavButton{
        display: block;
    }


    /*list view elements */

    .listElementBody{
        display: block;
    }

    .listElemetHeading{
        font-size: 20px;
        width: inherit;
        margin-bottom: 12px;
        color: aquamarine;
    }

    .listElemetDesc{
        font-size: 12px;
        width: inherit;
    }


    .mL15{
        margin-left: 0%;
    }

    .twitter_ovverride{
        margin-top: 20px;
    }

    .contactMeForm{
        width: 71%;
        margin: 45px
    }
    .formFrameWidth{
        width: 300px;
    }
}

@media only screen and (max-width: 1100px) and (min-width:412px) {

    /* navigation bar */
    .sidenav {
        position: absolute;
        width: 51%;
        height: 100%;
        margin-top: 55px;
        overflow-y: auto;
    }
    .display{
        display: block;
    }

    .notDisplay{
        display: none;
    }

    .homeMainBody{
        margin-left: 0px;
    }
    
    /*About Me Page */
    .aboutMeFirstPara{
        padding-top: 5px;
    }
    .aboutMeImageIntro{
        position: relative;
    }
    .aboutMeRoot{
        margin-left : 0%;
    }
    .aboutMeText{
        margin-left: 15%;
    }
   
   
    /*side nave button */
    .sideNavButton{
        display: block;
    }


    /*list view elements */

    .listElementBody{
        display: block;
    }

    .listElemetHeading{
        font-size: 20px;
        width: inherit;
        margin-bottom: 12px;
        color: aquamarine;
    }
    .listElemetDesc{
        font-size: 12px;
        width: inherit;
    }

    .mL15{
        margin-left: 0%;
    }

    .twitter_ovverride{
        margin-top: 20px;
    }

    .contactMeForm{
        width: 71%;
        margin: 45px
    }
    .formFrameWidth{
        width: 300px;
    }
}