.slide-container {
    margin-left: 15%;
    width: 100%;
    filter: brightness(50%);
 }

 .width100{
  width: 100%;
 }
  
  h3 {
    text-align: center; }
  
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    /* height: 300px; */
  }
  
  .each-slide span {
    /* padding: 20px; */
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  
  .each-fade {
    display: flex;
  }
  
  .each-fade .image-container {
    /* width: 75%; */
    overflow: hidden;
  }
  
  .each-fade .image-container img {
    width: 100%;
  }
  
  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }

  .homeImage{
    width: 100%;
    /* filter: blur(2px); */
  }

  .sliderText{
    position: absolute;
    color: white;
    width: 116px;
    margin-left: 53px;
    text-transform: uppercase;
    word-spacing: 7px;
    line-height: 42px;
    z-index: 2;
  }

  .sliderAnimation{
    animation:tracking-in-expand .7s cubic-bezier(.215,.61,.355,1.000) both
  }

  .slideTextColor{
    color: black;
  }

#createdOnCH, #createdByCH{
  display: inline;
}

#createdByCH{
  float: right;
  margin-right: 5%;
}

  @keyframes tracking-in-expand{0%{letter-spacing:-.5em;opacity:0}40%{opacity:.6}100%{opacity:1}}

  @media only screen and (max-width: 600px) {
    .slide-container{
      margin-left: 0%;
      height: 100%;
    }

    .homeImage{
      width: 700px;
      height: 750px;
    }

  }


  @media only screen and (max-width: 1100px) and (min-width:600px) {
    .slide-container{
      margin-left: 0%;
      height: 100%;
    }

    .homeImage{
      width: 1150px;
      height: 1500px;
    }

  }